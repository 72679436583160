<template>
  <div class="refundRecordView">
    <div class="contentView" v-loading="refundRecordLoading" element-loading-text="加载中...">
      <div class="tView flexView">
        <label>订单号</label>
        <label>衣物名称</label>
        <label>衣物条码</label>
        <label>衣物状态</label>
        <label>下单时间</label>
        <label>最后操作时间</label>
        <label>超时天数</label>
        <label>预计取衣时间</label>
        <label>清洗工厂</label>
      </div>
      <div class="listView" >
        <div class="listItem flexView" v-for="(item,index) in clothesList" :key="index">
          <label>{{item.orderNumber}}</label>
          <label>{{item.clothesName}}</label>
          <label>{{item.outClothesNum}}</label>
          <label>
            <el-tag type="danger">{{ item.clothesStatus }}</el-tag>
          </label>
          <label>{{item.orderTime}}</label>
          <label>{{item.lastOperateTime}}</label>
          <label>{{item.timeoutDays}}</label>
          <label>{{item.takeTime}}</label>
          <label>{{item.factoryName}}</label>
          <button class="btn-edit" @click="showDialogDetail(item.orderNumber)">详情</button>
        </div>
      </div>
      <div style="margin-top: 10px;float: right;">
        <el-pagination
            background
            layout="total,prev, pager, next"
            :page-size="pageSize"
            :total="total"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <transition name="userDetails">
      <order-detail-view ref="orderDetail"></order-detail-view>
    </transition>
  </div>
</template>

<script>
import {refundRecordPage, getRefundRecordByRefundId} from '@/api/clothes/order'
import PopView from '@/components/PopView'
import {listTimeOutClothesOrderPage} from '@/api/clothes/order'
import {getToken} from '@/utils/auth'
import store from "../../../store";
import OrderDetailView from "@/view/timeout/components/orderDetailView";

export default {
  name: "record",
  components: {
    PopView,
    OrderDetailView
  },
  data() {
    return {
      dialogVisible: false,
      currentPage: 1,
      pageSize: 20,
      total: 0, // 总数量
      type: '1',
      clothesList: [],
      sortValue:'1',
      value1: '',
      refundRecordDetail: {},
      refundRecordUser: {},
      refundRecordLoading: false,
      refundViewLoading: false
    }
  },
  created() {
    if (getToken()) {
      const userInfo = store.getters.userInfo
      this.tenantId = userInfo.storeId
      this.washingTimeOutClothesOrderPage()
    }
  },
  methods: {
    washingTimeOutClothesOrderPage() {
      this.loading = true
      const params = new URLSearchParams()
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      params.append('processingStatus', 2)
      params.append('tenantId', this.tenantId)
      params.append('categoryId', '')
      params.append('clothesTypeStatus', '')
      params.append('sortValue', this.sortValue)

      listTimeOutClothesOrderPage(params).then(res => {
        this.loading = false
        this.clothesList = res.data.data.clothesOrderDetailsList
        this.total = res.data.data.total
      })
    },
    getRefundRecordDetail(item) {
      this.refundViewLoading = true
      this.type = item.refundTypeName;
      getRefundRecordByRefundId(item.refundId).then(res => {
        this.refundViewLoading = false
        console.log(res);
        this.refundRecordDetail = res.data.data
        this.refundRecordUser = res.data.data.refundRecordUser
        this.dialogVisible = true
      })
    },
    // 处理
    showDialogDetail(orderNumber) {
      this.dialogTableVisible = true
      this.orderNumber = orderNumber
      this.$refs.orderDetail.orderNumber = orderNumber
      this.$refs.orderDetail.getClothesOrderDetails()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.washingTimeOutClothesOrderPage()
    },
  }
}
</script>

<style scoped lang="scss">
.refundRecordView {
  text-align: center;

  .filtrate {
    display: flex;
    height: 44px;
    padding: 20px 25px 0 25px;
  }

  > .contentView .flexView {
    display: flex;
    padding: 15px 140px 15px 25px;

    > label {
      flex: 1;
    }

    > label:nth-child(2) {
      flex: 2;
    }

    > label:nth-child(10) {
      flex: 2;
    }
  }

  .contentView {
    > .tView {
      line-height: 40px;
      padding: 0 140px 0 25px;
      font-size: 13px;
      color: #666;
      text-align: center;
    }

    > .listView {
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 25px;

      > .listItem {
        position: relative;
        line-height: 20px;
        font-size: 14px;
        color: #333;
        background: #f8f8f8;
        text-align: center;

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 30px;
          margin: auto;
          width: 65px;
          height: 36px;
          border-radius: 6px;
          background: #3370ff;
          font-size: 14px;
          color: #fff;
        }
      }

      > .listItem:nth-child(odd) {
        background: #fff;
      }

      > .listItem:hover {
        background: rgba(66, 153, 255, 0.1);
      }
    }
  }
}

button {
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}


.res {
  height: 100%;
  background: #fff;
  border-radius: 1px;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: auto;

  .detail_left, .detail_right {
    width: calc(50% - 12px);
    margin-right: 6px;
    margin-bottom: 6px;
    box-shadow: .6px 1px 1px rgba(166, 190, 255, .6);
    border-radius: 1px;

    .title {
      text-align: center;
      background: #f6faff;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
    }

    li {
      font-size: 15px;
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid #f0f3f6;
      padding-left: 12px;
      padding-right: 12px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  .detail_right {
    margin-right: 0;
  }

  .detail_bottom {
    width: 100%;
    padding: 12px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 116, 225, 0.22);

    .title {
      text-align: center;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
    }

    .table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;

      tr {
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        border-bottom: 1px dotted #ccc;
        font-size: 15px;

        th {
          font-size: 15px;
        }
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in .7s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out .7s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in .7s ease
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out .7s ease forwards
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
