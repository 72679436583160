<template>
  <div class="refundRecordView">
    <!--    <div class="filtrate">-->
    <!--      <el-date-picker-->
    <!--          type="daterange"-->
    <!--          range-separator="至"-->
    <!--          start-placeholder="开始日期"-->
    <!--          end-placeholder="结束日期">-->
    <!--      </el-date-picker>-->
    <!--      <el-button type="primary" size="small" style="margin-left: 20px">确认</el-button>-->
    <!--    </div>-->
    <div class="contentView" v-loading="timeoutRecordLoading" element-loading-text="加载中...">
      <div class="tView flexView">
        <label>订单号</label>
        <label>衣物名称</label>
        <label>衣物条码</label>
        <label>衣物状态</label>
        <label>下单时间</label>
        <label>最后操作时间</label>
        <label>超时天数</label>
        <label>预计取衣时间</label>
        <label>清洗工厂</label>
      </div>
      <div class="listView" >
        <div class="listItem flexView" v-for="(item,index) in clothesList" :key="index">
          <label>{{ item.orderNumber }}</label>
          <label>{{ item.clothesName }}</label>
          <label>{{ item.outClothesNum }}</label>
          <label>
            <el-tag type="danger">{{ item.clothesStatus }}</el-tag>
          </label>
          <label>{{ item.orderTime }}</label>
          <label>{{ item.lastOperateTime }}</label>
          <label>{{ item.timeoutDays }}</label>
          <label>{{item.takeTime}}</label>
          <label>{{item.factoryName}}</label>
          <el-button-group class="btn-edit">
            <el-button type="success" size="medium" style="margin-right: 1px;" @click.stop="handleUpdateProcessingStatus(item.id)">
              处理
            </el-button>
            <el-button type="primary" size="medium" @click.stop="showDialogDetail(item.orderNumber)">查看</el-button>
          </el-button-group>
          <!--          <button class="btn-edit" @click="getRefundRecordDetail(item)">详情</button>-->
        </div>
      </div>
      <div style="margin-top: 10px;float: right;">
        <el-pagination
            background
            layout="total,prev, pager, next"
            :page-size="pageSize"
            :total="total"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <transition name="userDetails">
      <order-detail-view ref="orderDetail"></order-detail-view>
    </transition>
  </div>
</template>

<script>
import PopView from '@/components/PopView'
import {listTimeOutClothesOrderPage} from '@/api/clothes/order'
import {updateTimeOutClothesProcessingStatus, clothesByClothesNumber} from '@/api/clothes/clothes'
import {getToken} from '@/utils/auth'
import store from "../../../store";
import OrderDetailView from "@/view/timeout/components/orderDetailView";

export default {
  name: "record",
  components: {
    PopView,
    OrderDetailView
  },
  data() {
    return {
      dialogVisible: false,
      currentPage: 1,
      pageSize: 20,
      total: 0, // 总数量
      type: '1',
      clothesList: [],
      sortValue: '1',
      value1: '',
      refundRecordDetail: {},
      refundRecordUser: {},
      timeoutRecordLoading: false,
      refundViewLoading: false,
      orderDetailOption: {},
      orderInfoLoading: false,
      dialogTableVisible: false,
      orderNumber:''
    }
  },
  created() {
    if (getToken()) {
      const userInfo = store.getters.userInfo
      this.tenantId = userInfo.storeId
      this.washingTimeOutClothesOrderPage()
    }
  },
  methods: {
    washingTimeOutClothesOrderPage() {
      this.timeoutRecordLoading = true
      const params = new URLSearchParams()
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      params.append('processingStatus', 1)
      params.append('tenantId', this.tenantId)
      params.append('categoryId', '')
      params.append('clothesTypeStatus', '')
      params.append('sortValue', this.sortValue)
      listTimeOutClothesOrderPage(params).then(res => {
        this.timeoutRecordLoading = false
        this.clothesList = res.data.data.clothesOrderDetailsList
        this.total = res.data.data.total
      })
    },
    handleUpdateProcessingStatus(id) {
      this.$confirm('确认要处理超时衣物, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = new URLSearchParams()
        params.append('id', id)
        params.append('processingStatus', 2)
        updateTimeOutClothesProcessingStatus(params).then(res => {
          console.log(res);
          this.washingTimeOutClothesOrderPage()
        })
        this.$message({
          type: 'success',
          message: '处理成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消处理'
        });
      });
    },
    // 处理
    showDialogDetail(orderNumber) {
      this.dialogTableVisible = true
      this.orderNumber = orderNumber
      this.$refs.orderDetail.orderNumber = orderNumber
      this.$refs.orderDetail.getClothesOrderDetails()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.washingTimeOutClothesOrderPage()
    },

  }
}
</script>

<style scoped lang="scss">
.refundRecordView {
  //text-align: center;

  .filtrate {
    display: flex;
    height: 44px;
    padding: 20px 25px 0 25px;
  }

  > .contentView .flexView {
    display: flex;
    padding: 15px 140px 15px 25px;

    > label {
      flex: 1;
    }

    > label:nth-child(5) {
      flex: 1.5;
    }

    > label:nth-child(6) {
      flex: 1.5;
    }

    //
    //> label:nth-child(10) {
    //  flex: 2;
    //}
  }

  .contentView {
    > .tView {
      line-height: 40px;
      padding: 0 140px 0 25px;
      font-size: 13px;
      color: #666;
      text-align: center;
    }

    > .listView {
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 25px;

      > .listItem {
        position: relative;
        line-height: 20px;
        font-size: 14px;
        color: #333;
        background: #f8f8f8;
        text-align: center;

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 30px;
          margin: auto;
          //width: 65px;
          //height: 36px;
          //border-radius: 6px;
          //background: #3370ff;
          //font-size: 14px;
          //color: #fff;
        }
      }

      > .listItem:nth-child(odd) {
        background: #fff;
      }

      > .listItem:hover {
        background: rgba(66, 153, 255, 0.1);
      }
    }
  }
}

//button {
//  background: transparent;
//  border: none;
//  outline: none;
//  box-sizing: border-box;
//  cursor: pointer;
//}


.res {
  height: 100%;
  background: #fff;
  border-radius: 1px;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: auto;

  .detail_left, .detail_right {
    width: calc(50% - 12px);
    margin-right: 6px;
    margin-bottom: 6px;
    box-shadow: .6px 1px 1px rgba(166, 190, 255, .6);
    border-radius: 1px;

    .title {
      text-align: center;
      background: #f6faff;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
    }

    li {
      font-size: 15px;
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid #f0f3f6;
      padding-left: 12px;
      padding-right: 12px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  .detail_right {
    margin-right: 0;
  }

  .detail_bottom {
    width: 100%;
    padding: 12px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 116, 225, 0.22);

    .title {
      text-align: center;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
    }

    .table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;

      tr {
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        border-bottom: 1px dotted #ccc;
        font-size: 15px;

        th {
          font-size: 15px;
        }
      }
    }
  }
}

.memberDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;
  z-index: 100;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 90px;*/
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
       font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: '';
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in .7s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out .7s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in .7s ease
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out .7s ease forwards
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
