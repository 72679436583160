<template>
  <div class="refundIndexView content-index">
    <div class="topView">
      <div class="tView">
         <div style="display:flex; flex-direction: row;">
            <div>
              <el-page-header
                style="width: 100%;padding:10px"
                @back="$router.go(-1)"
                content="超时衣物"
              >
              </el-page-header>
            </div>
            <div>
              <el-tooltip content="每晚凌晨刷新,待收衣、已收衣、已送洗、已入厂的可被定义为超时；已出厂、已到店、已上挂、已取衣的订单，不统计超时" placement="top">
                <i class="el-icon-info" style="font-size: 15px;"></i>
              </el-tooltip>
            </div>
         </div>
      </div>
      <div class="menuView">
        <button v-for="(tab,index) in tabs" :key="index" :class="{'select':currentTab === tab.id}" @click="toggleTab(tab.id)">
          {{ tab.title }}
        </button>
      </div>
    </div>
    <div class="contentView">
      <timeout-not-taken v-if="currentTab === 0"></timeout-not-taken>
      <wash-timeout v-if="currentTab === 1"></wash-timeout>
    </div>
  </div>
</template>

<script>
import WashTimeout from './components/washTimeout'
import TimeoutNotTaken from './components/timeoutNotTaken'

export default {
  name: 'setting',
  components: {
    WashTimeout,
    TimeoutNotTaken,
  },
  data() {
    return {
      tabs: [
        {id: 0, title: "未处理超时"},
        {id: 1, title: "已处理超时"},
      ],
      currentTab: 0,
    }
  },
  methods: {
    toggleTab: function (index) {
      this.currentTab = index;
    },
  }
}
</script>

<style scoped lang="scss">
.refundIndexView {
  position: relative;
  font-family: PingFangSC-Regular; 
  padding: 20px;
   overflow: auto;

  > .topView {
    padding-bottom: 20px;
    > .tView {
      color: #333;
      font-size: 24px;
      font-family: PingFang-SC-Bold;
      line-height: 40px;
    }

    > .menuView {
      position: absolute;
      width: 380px;
      display: flex;
      top: 27px;
      left: 0;
      right: 0;
      margin: auto;

      > button.select {
        position: relative;
        color: #3370ff;
        font-family: PingFangSC-Semibold;
      }

      > button.select:after {
        content: '';
        left: 0;
        right: 0;
        bottom: -6px;
        position: absolute;
        width: 24px;
        height: 2px;
        background: #3370ff;
        margin: auto;
        border-radius: 1px;
      }

      > button {
        font-size: 16px;
        color: #333;
        flex: 1;
        line-height: 30px;
        height: 30px;
        padding: 0;
      }
    }
  }
  .contentView{

  }
}


button {
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
