<template>
  <div class="clothesDetailView" v-if="dialogTableVisible">
    <div class="topView">
      <div class="backView">
        <el-button
          class="btn-back"
          icon="el-icon-arrow-left"
          @click="dialogTableVisible = false"
        >
          返回
        </el-button>
      </div>
      <div class="contentView">
        <div class="tView">订单详情</div>
      </div>
    </div>
    <div
      class="contentView"
      style="height: 669px; margin-top: 20px;"
      v-loading="orderInfoLoading"
      element-loading-text="加载中..."
    >
      <div class="clothesInfo-contentView">
        <div style="display: flex;">
          <div class="infoView">
            <div>
              <label class="label-leftText">单号：</label>
              <label>{{ orderDetailOption.orderNumber }}</label>
            </div>
            <div>
              <label class="label-leftText">订单来源：</label>
              <label>{{ orderDetailOption.orderSource }}</label>
            </div>
            <div>
              <label class="label-leftText">消费日期：</label>
              <label>{{ orderDetailOption.orderTime }}</label>
            </div>
            <div>
              <label class="label-leftText">订单状态：</label>
              <label>{{ orderDetailOption.orderStatus }}</label>
            </div>
            <div>
              <label class="label-leftText">支付状态：</label>
              <label style="color: red;">
                {{ orderDetailOption.payStatus }}
              </label>
            </div>
            <div>
              <label class="label-leftText">所属门店：</label>
              <label>{{ orderDetailOption.storeName }}</label>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">用户姓名：</label>
              <label>{{ orderDetailOption.orderRealName }}</label>
            </div>
            <div>
              <label class="label-leftText">手机号：</label>
              <label v-hidephone>{{ orderDetailOption.orderUserPhone }}</label>
            </div>
            <div>
              <label class="label-leftText">地址：</label>
              <label>{{ orderDetailOption.userAddress }}</label>
            </div>
            <div>
              <label class="label-leftText">操作员：</label>
              <label>{{ orderDetailOption.storeUserName }}</label>
            </div>
          </div>
        </div>
        <div class="contentView">
          <div class="tView">
            衣物详情(件数: 1):
            <!--                                <el-button type="text">修改服务人员</el-button>-->
          </div>
          <div class="cashContentView">
            <div class="headerView flexView" style="color: #999;">
              <label>条码号</label>
              <label>格架号</label>
              <label>状态</label>
              <label>衣物名称</label>
              <label>颜色</label>
              <label>原价/折后价</label>
              <label>优惠券</label>
              <label>操作</label>
            </div>
            <div
              class="flexView"
              v-for="(item, index) in orderDetailOption.clothesList"
              :key="index"
            >
              <label>
                <el-tag
                  size="mini"
                  type="info"
                  v-if="item.clothesStatus === '已退洗'"
                >
                  退
                </el-tag>
                <el-tag size="mini" v-if="item.accessoryNumber">附</el-tag>
                {{ item.outClothesNum }}
              </label>
              <label></label>
              <label>{{ item.clothesStatus }}</label>
              <label>{{ item.clothesName }}</label>
              <label>{{ item.colorName }}</label>
              <label v-if="!item.accessoryNumber">
                {{ item.originalPrice }}元/{{ item.currentPrice }}元
              </label>
              <label v-else>0元</label>
              <label v-else></label>
              <label>
                {{ item.couponId != null ? '已使用' : '未使用' }}
              </label>
              <label>
                <el-button
                  type="primary"
                  size="mini"
                  @click="showDialogDetail(item)"
                >
                  详情
                </el-button>
              </label>
            </div>
          </div>
        </div>
        <div class="payInfoView">
          <div class="tView">
            金额
          </div>
          <div>
            <div class="amtView">
              <div>
                <label class="label-leftText">衣物原价：</label>
                <label class="label-amt">
                  ¥ {{ orderDetailOption.productOriginalPrice }}
                </label>
              </div>
              <div>
                <label class="label-leftText">运费：</label>
                <label class="label-amt">¥ {{ orderDetailOption.fare }}</label>
              </div>
              <div>
                <label class="label-leftText">折后总价：</label>
                <label class="label-amt">
                  ¥ {{ orderDetailOption.actuallyPaidPrice }}
                </label>
              </div>
              <div>
                <label class="label-leftText">订单总额：</label>
                <label class="label-amt">
                  ¥ {{ orderDetailOption.actuallyPaidPrice }}
                </label>
              </div>
            </div>
            <div class="payTypeView">
              <div class="payTypeItem">
                <label class="label-leftText">支付方式：</label>
                <label class="label-name">{{ orderDetailOption.payWay }}</label>
              </div>
            </div>
            <div class="remarkView">
              <div>
                <label class="label-leftText">顾客备注信息：</label>
                <label>{{ orderDetailOption.orderRemark }}</label>
              </div>
              <div>
                <label class="label-leftText">店长备注信息：</label>
                <label>{{ orderDetailOption.storeManagerRemark }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--衣物详情-->
    <pop-view
      id="order-detailViewPopView"
      :show="detailView"
      width="600px"
      title="衣物详情"
      @cancel="detailView = false"
    >
      <div class="menuView">
        <button
          style="background: transparent;"
          :class="{ select: currentTab === item.val }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="handleDetailTab(item.val)"
        >
          {{ item.title }}
        </button>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px;"
        v-if="currentTab === 'info'"
      >
        <div style="display: flex;">
          <div class="infoView">
            <div>
              <label class="label-leftText">衣物名称：</label>
              <label>{{ clothes.clothesName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物号：</label>
              <label>{{ clothes.clothesNum }}</label>
            </div>
            <div>
              <label class="label-leftText">洗涤方式：</label>
              <label>{{ clothes.clothesServiceName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物状态：</label>
              <label>{{ clothes.clothesStatus }}</label>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">颜色：</label>
              <label>{{ clothes.colorName }}</label>
            </div>
            <div>
              <label class="label-leftText">品牌：</label>
              <label>{{ clothes.brandName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物价格：</label>
              <label>¥{{ clothes.currentPrice }}</label>
            </div>
          </div>
        </div>
        <div class="infoView">
          <div>
            <label class="label-leftText">预计取衣时间：</label>
            <label>{{ clothes.estimatedWashTime }}</label>
          </div>
          <div>
            <label class="label-leftText">洗后效果：</label>
            <label>{{ clothes.effects }}</label>
          </div>
          <div>
            <label class="label-leftText">瑕疵：</label>
            <label>{{ clothes.blemishes }}</label>
          </div>
          <div>
            <label class="label-leftText">备注：</label>
            <label>{{ clothes.remark }}</label>
          </div>
        </div>
        <div
          style="
            display: flex;
            margin-top: 20px;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: space-between;
          "
        >
          <el-image
            style="margin-left: 10px; width: 22%; margin-bottom: 10px;"
            v-if="clothes.photos !== null"
            v-for="url in clothes.photos"
            :key="url"
            :src="url"
            :preview-src-list="clothes.photos"
            lazy
          ></el-image>
        </div>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px;"
        v-if="currentTab === 'status'"
      >
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in clothesStatusList"
            :key="index"
            color="#0bbd87"
            :timestamp="activity.operateTime"
          >
            {{ activity.description }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </pop-view>
  </div>
</template>

<script>
import { getClothesOrderDetails } from '@/api/clothes/order'
import PopView from '@/components/PopView'

export default {
  name: 'orderDetailView',
  components: {
    PopView,
  },
  data() {
    return {
      orderDetailOption: {},
      orderInfoLoading: false,
      dialogTableVisible: false,
      orderNumber: '',
      detailView: false,
      clothes: {},
      clothesStatusList: [],
      tabs: [
        { val: 'info', title: '信息' },
        { val: 'status', title: '状态' },
      ],
      currentTab: 'info',
    }
  },
  created() {},
  methods: {
    // 获取订单详情
    getClothesOrderDetails() {
      this.dialogTableVisible = true
      this.orderInfoLoading = true
      getClothesOrderDetails(this.orderNumber)
        .then((response) => {
          this.orderInfoLoading = false
          console.log(response)
          this.orderDetailOption = response.data.data
        })
        .catch((res) => {})
    },
    showDialogDetail(item) {
      let _this = this
      _this.clothesStatusList = []
      _this.clothes = item
      console.log(_this.clothes.clothesStatusList)
      _this.clothes.clothesStatusList.forEach(function (item, index) {
        if (item.active) {
          _this.clothesStatusList.push(item)
        }
      })
      this.detailView = true
    },
    handleDetailTab(val) {
      this.currentTab = val
    },
  },
}
</script>

<style scoped lang="scss">
.clothesDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;
  z-index: 100;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
      font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: '';
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }
    }
  }
}

.clothesInfo-contentView {
  > div {
    > .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 80px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }

  > .contentView {
    > div {
      padding-left: 20px;
      padding-right: 20px;
      line-height: 20px;
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      > button {
        position: absolute;
        top: 0;
        right: 20px;
        line-height: 50px;
        height: 50px;
        background: transparent;
        font-size: 14px;
        padding: 0;
        color: #3370ff;
      }
    }

    .cashContentView {
      max-height: 300px;
      overflow: auto;

      > .headerView {
        color: #999;
      }
    }

    > .cashContentView .flexView {
      display: flex;
      text-align: center;
      font-size: 14px;
      color: #333;
      line-height: 20px;
      padding: 6px 0;

      > label {
        flex: 1;
        cursor: pointer;
      }

      > label:nth-child(1) {
        text-align: left;
        flex: 0.5;
      }

      > label:nth-child(2) {
        flex: 1;
      }
    }
  }

  > .payInfoView {
    padding-bottom: 25px;

    > div {
      display: flex;
      color: #333;
      line-height: 20px;
      font-size: 14px;
      padding: 6px 20px 6px 20px;
    }

    > div .label-leftText {
      width: 80px;
      min-width: 80px;
      color: #999;
    }

    > div .label-amt {
      color: #ff5e56;
      font-family: DINAlternate-Bold;
    }

    > div {
      > div {
        margin-top: 10px;
      }

      > div.payTypeView {
        margin-left: 20px;
      }

      > div.remarkView {
        margin-left: 20px;
      }
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      > button {
        position: absolute;
        top: 0;
        right: 20px;
        line-height: 50px;
        height: 50px;
        background: transparent;
        font-size: 14px;
        padding: 0;
        color: #3370ff;
      }
    }
  }
}

#order-detailViewPopView > .popView-contentView {
  > .menuView {
    position: absolute;
    width: 88px;
    display: flex;
    top: 45px;
    left: 0;
    right: 0;
    margin: auto;

    > button {
      font-size: 16px;
      color: #333;
      flex: 1;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }

    > button.select {
      position: relative;
      color: #3370ff;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
    }

    > button.select:after {
      content: '';
      left: 0;
      right: 0;
      bottom: 0px;
      position: absolute;
      width: 24px;
      height: 2px;
      background: #3370ff;
      margin: auto;
      border-radius: 1px;
    }
  }

  > .contentView {
    .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 99px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }
}
</style>
